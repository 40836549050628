<template>
  <div>
    <Breadcrumb :breadcrumbList="breadcrumbList" />
    <div class="content-layout-fixer">
      <div id="divManageHomeWrapper" class="manage-my-business">
        <VueTabs @selectedTab="setActiveTab">
          <VueTab
            v-for="(item, index) in tabItems"
            :selected="activeTabIndex === index"
            :key="index"
            :name="item"
            :id="index"
          >
            <component :is="getComponent(index)" v-if="activeTabIndex === index" />
          </VueTab>
        </VueTabs>
      </div>
    </div>
  </div>
</template>
<script>
import { ref } from 'vue';
import TrackPreorderHistory from '../secure/manageMyBusiness/TrackPreorderHistory.vue';
import SaleTracking from '../secure/manageMyBusiness/SaleTracking.vue';
import ScoreTracking from '../secure/manageMyBusiness/ScoreTracking.vue';
import TrackEarnings from '../secure/manageMyBusiness/TrackEarnings.vue';
import Breadcrumb from '@/components/brand/BreadCrumb/Breadcrumb.vue';
import VueTabs from '@/components/shared/VueTabs/VueTabs.vue/';
import VueTab from '@/components/shared/VueTab/VueTab.vue';
import FollowProducts from '../secure/manageMyBusiness/FollowProducts.vue';

export default {
  components: {
    TrackPreorderHistory,
    VueTabs,
    VueTab,
    Breadcrumb,
    SaleTracking,
    ScoreTracking,
    TrackEarnings,
    FollowProducts,
  },
  setup() {
    const activeTabIndex = ref(0);

    const tabItems = ref([
      'SATIŞINI TAKİP ET',
      'KAZANCINI TAKİP ET',
      'ÜRÜNLERİNİ TAKİP ET',
      'PUANINI TAKİP ET',
      'ÖN SİPARİŞ GEÇMİŞİNİ TAKİP ET',
    ]);

    const breadcrumbList = ref([
      {
        name: 'Ana Sayfa',
        link: '/secure/feed',
      },
      {
        name: 'Performans Takibi',
        link: '/secure/manage-my-business',
      },
    ]);

    const getComponent = index => {
      const components = [
        'SaleTracking',
        'TrackEarnings',
        'FollowProducts',
        'ScoreTracking',
        'TrackPreorderHistory',
      ];
      return components[index];
    };

    const setActiveTab = index => {
      activeTabIndex.value = index;
    };

    return {
      activeTabIndex,
      tabItems,
      getComponent,
      breadcrumbList,
      setActiveTab,
    };
  },
};
</script>
<style scoped lang="scss">
.manage-my-business {
  margin-left: calc(#{palette-space-level('20')} * -1);
  padding: palette-space-level('30') 0 palette-space-level('40') 0;
}
</style>
